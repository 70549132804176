import { lazy } from "react";
import HomeLayout from "./layout/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./pages/LandingPage/Index")),
  },

  {
    exact: true,
    path: "/know-your-coach",
    layout: HomeLayout,
    component: lazy(() => import("./pages/KnowYourCoach/Index")),
  },

  {
    exact: true,
    path: "/types-of-yoga",
    layout: HomeLayout,
    component: lazy(() => import("./pages/TypesOfAsana/Index")),
  },
  {
    exact: true,
    path: "/schedule-and-timing",
    layout: HomeLayout,
    component: lazy(() => import("./pages/ScheduleAndTiming/Index")),
  },



  {
    exact: true,
    path: "/asana-info",
    layout: HomeLayout,
    component: lazy(() => import("./pages/AsanaDetails/Index")),
  },
  {
    exact: true,
    path: "/class-info",
    layout: HomeLayout,
    component: lazy(() => import("./pages/ClassesDetails/Index")),
  },
];
