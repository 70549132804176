import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';

const Terms = (props) => {



    const terms = [
        { header: "Introduction", description: "These terms and conditions govern the access and participation in online yoga classes provided by \“YUJ by Surekha\" through our online platform. By registering for or participating in our online yoga classes, you agree to these Terms. If you do not agree, you should not participate in our classes." },
        {
            header: "Eligibility",
            description: (
                <>
                    "Participants must be at least 18 years old to register and attend the classes.
                    <br />
                    For minors (below 18 years), registration and participation are only allowed with parental or guardian consent.
                    <br />
                    Participants must ensure they are physically fit to practice yoga and should consult a medical professional before beginning any exercise program."
                </>

            )
        },
        {
            header: "Registration and Payment",
            description: (
                <>
                    Registration is required to attend any online class. Participants must provide accurate and
                    complete information during registration.
                    <br />
                    Class fees must be paid in full prior to participation. Fees are non-refundable and can
                    neither be extended or transferred to another client.
                    <br />
                    Fees will be decided according to the package selected and designed.
                    <br />
                    Fees of the package will be on a monthly basis and not on a per-session basis
                    <br />
                    In case of irregularities of clients in attendance, no negotiations will be held for extensions
                    of sessions.
                    <br />
                    We reserve the right to revise class fees at any time, but changes will not affect fees for
                    already paid bookings.

                </>
            )
        },
        {
            header: "Cancellations and Refunds",
            description: (
                <>
                    Cancellations of registrations made by participants must be notified at least 24 hours
                    before the class begins.
                    <br />
                    If the service provider cancels a class due to unforeseen circumstances (e.g., technical
                    issues or instructor unavailability), participants will be offered a reschedule.
                    <br />
                    No refunds will be provided for classes missed due to participant’s own technical issues or
                    personal reasons.
                    <br />
                    Package may be extended for clients only up to 15 days in case of unforeseen medical
                    emergencies, provided that relevant medical reports are submitted.

                </>
            )
        },
        {
            header: "Health and Safety Disclaimer",
            description: (
                <>
                    Prior to the designing of a package, clients must disclose medical history and information
                    along with current medical treatments, conditions and medications if any.
                    <br />
                    Above mentioned details must be provided for safety of the clients, enabling the service
                    providers to customize their services accordingly, providing optimal care and avoiding
                    adverse health effects.
                    <br />
                    Participants acknowledge that yoga involves physical exertion, and participation is entirely
                    at their own risk.
                    <br />
                    The service provider is not liable for any injuries, health conditions, or adverse effects
                    experienced as a result of participating in yoga classes.
                    <br />
                    Participants must stop practicing if they feel any discomfort, pain, or adverse symptoms
                    during the session.
                    <br />
                    Participants understand that the service provider is not a medical organization and its
                    instructors are not licensed medical professionals.

                </>
            )

        },
        {
            header: "Intellectual Property",
            description: (
                <>
                    All materials provided in the online classes, including videos, written instructions, audio
                    files, and other media, are the intellectual property of the service provider.
                    <br />
                    Participants are granted a limited, non-transferable, non-exclusive license to access these
                    materials for personal, non-commercial use.
                    <br />
                    Participants are prohibited from recording, copying, distributing, or sharing any content
                    without the service provider’s prior written consent.

                </>
            )
        },
        {
            header: "Code of Conduct",
            description: (
                <>
                    Participants must behave respectfully towards instructors and other participants during
                    classes.
                    <br />
                    Any form of harassment, abusive language, or inappropriate conduct will not be tolerated
                    and may result in immediate suspension or termination of access to classes without a
                    refund.
                    <br />
                    Participants are expected to follow the instructor's guidance and refrain from attempting
                    advanced postures or modifications unless instructed.

                </>
            )
        },
        {
            header: "Technical Requirements",
            description: (
                <>
                    Participants are responsible for ensuring they have the required equipment and internet
                    connection to access the online classes.
                    <br />
                    The service provider will not be responsible for any technical issues faced by participants,
                    including but not limited to slow internet speeds or device malfunctions.

                </>
            )
        },
        {
            header: "Privacy Policy",
            description: (
                <>
                    We collect personal data during registration to deliver yoga services. This data is protected
                    according to the provisions of the Information Technology Act, 2000 and The Personal Data
                    Protection Bill, 2019 (if applicable).
                    <br />
                    Personal data will not be shared with third parties without prior consent, except as required
                    by law.
                    <br />
                    For more details, please refer to our Privacy Policy

                </>
            )
        },
        {
            header: "Limitation of Liability",
            description: (
                <>
                    To the extent permitted by law, the service provider, its employees, agents, and instructors
                    are not liable for any indirect, incidental, or consequential damages arising from your
                    participation in online yoga classes.
                    <br />
                    In no event shall the service provider’s liability exceed the amount paid by the participant
                    for the specific class or service that gave rise to the claim.

                </>
            )
        },
        {
            header: "Governing Law and Dispute Resolution",
            description: (
                <>
                    These Terms are governed by and construed in accordance with the laws of India.
                    In the event of any disputes, the parties agree to attempt to resolve the dispute amicably
                    through mediation. If the dispute remains unresolved, it shall be subject to the exclusive
                    jurisdiction of the courts in Pune, India.stributing, or sharing any content
                    without the service provider’s prior written consent.

                </>
            )
        },
        {
            header: "Modification of Terms",
            description: (
                <>
                    The service provider reserves the right to modify these Terms at any time. Participants will
                    be notified of any significant changes via email or a notification on the Platform. Continued
                    participation in the classes after modifications will be considered acceptance of the new
                    terms.

                </>
            )
        },
        {
            header: "Contact Information",
            description: (
                <>
                    If you have any questions or concerns regarding these Terms,
                    please contact us at contact@yujbysurekha.co.in or +91 9632572111.

                </>
            )
        },
        {
            header: "Non-compliance",
            description: (
                <>
                    No results are guaranteed if clients do not follow recommended routines provided by the
                    service providers.

                </>
            )
        },
    ];

    return (
        <div>
            <Dialog
                open={props?.open}
                onClose={props?.onClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: '#E0E5B6',
                        padding: '20px',
                    },
                }}
            >
                <DialogTitle align="center">
                    <Typography variant="h6">
                        Terms and Conditions for “YUJ by Surekha”
                    </Typography>
                    <Typography variant="subtitle1">
                        (Hereinafter referred to as “Service provider”)
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {terms.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={<Typography variant="subtitle1"><strong>{index + 1}. {item.header}</strong></Typography>}
                                    secondary={<Typography mt={2} variant="body2">{item.description}</Typography>}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Typography mt={2} variant="body2">
                        ---
                        <br />
                        By enrolling in and attending the online yoga classes, you agree to these Terms and
                        Conditions.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props?.onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Terms;
