import { colors, responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { typography } from "./typography";

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Alata', sans-serif",
  },

  palette: {
    mode: "light",

    action: {
      primary: "#000",
    },

    background: {
      default: "#f4f5f7",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },

    primary: {
      main: "#0a0a0a",
      dark: "#262626",
      light: "#667085",
    },

    secondary: {
      main: "#093B2B",
      light: "#FFFFFF",
      gray:"#344054",
    },

    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },

    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },

    error: {
      main: "#ED2124",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },

    common: {
      black: "#000000",
    },
  },
};

const baseOptions = {
  typography: {
    ...typography,
    fontFamily: "'Alata', sans-serif",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#fff",
          background: "#023927",
          borderColor: "#023927",
          height: "44px",
          padding: "3px 15px",
          fontSize: "16px",
          fontWeight: 400,
          border: "1px solid #023927",
          fontFamily: "'Alata', sans-serif",
          borderRadius: "25px",
          textTransform: "capitalize",
          "&:hover": {
            color: "#023927",
            background: "#FFF",
            borderColor: "#023927",
          },
        },

        contained1: {
          color: "#fff",
          background: "#023927",
          borderColor: "#023927",
          height: "54px",
          padding: "12px 50px",
          fontSize: "16px",
          fontWeight: 400,
          border: "1px solid #023927",
          fontFamily: "'Alata', sans-serif",
          borderRadius: "25px",
          textTransform: "capitalize",
          "&:hover": {
            color: "#023927",
            background: "#FFF",
            borderColor: "#023927",
          },
        },

        contained2: {
          color: "#fff",
          background: "#023927",
          borderColor: "#023927",
          height: "54px",
          padding: "12px 50px",
          fontSize: "16px",
          fontWeight: 400,
          border: "1px solid #023927",
          fontFamily: "'Alata', sans-serif",
          borderRadius: "25px",
          textTransform: "capitalize",
          "&:hover": {
            color: "#8D3D37",
            background: "#FFF",
            borderColor: "#8D3D37",
          },
        },

        contained3: {
          color: "#fff",
          background: "#023927",
          borderColor: "#023927",
          height: "44px",
          padding: "3px 15px",
          fontSize: "16px",
          fontWeight: 400,
          border: "1px solid #023927",
          fontFamily: "'Alata', sans-serif",
          borderRadius: "57px",
          textTransform: "capitalize",
          "&:hover": {
            color: "#8D3D37",
            background: "#FFF",
            borderColor: "#8D3D37",
          },
        },
        

        outlined: {
          color: "#8231d3",
          background: "transparent",
          borderColor: "#8231d3",
          height: "44px",
          padding: "3px 15px",
          fontSize: "15px",
          fontWeight: 400,
          border: "1px solid",
          fontFamily: "'Jost', sans-serif",
          borderRadius: "4px",
          "&:hover": {
            color: "#a158e0",
            background: "rgba(129, 49, 211, 0.1)",
            borderColor: "#a158e0",
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: "0",
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: { top: "47px" },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          height: "50px",

          "& .MuiOutlinedInput-root": {
            height: "100%",
            borderRadius: "8px",
            background:'#FFF',

            "& .MuiOutlinedInput-input": {
              padding: "12px 20px",
            },
          },

          "& .MuiInputBase-input": {
            
            "&::placeholder": {
              color: "#667085",
              fontSize: "16px",
              fontWeight: 400,
            },
          },

          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#023927",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
            // borderColor: "#8231d3 !important",
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: "#000",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16px",
          "&.colorSecondary": {
            color: "#8d8989",
          },
        },
      },
    },
  },
};

export const createCustomeTheme = (config = {}) => {
  let theme = createTheme({ ...baseOptions, ...themesOptions });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
