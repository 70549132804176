import React, { useContext, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SettingsContext from "./context/SettingsContext";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import AppRouter from "./AppRouter";
import { createCustomeTheme } from "../src/theme";

function App() {
  const themeSetting = useContext(SettingsContext);
  const theme = createCustomeTheme({
    theme: createTheme(themeSetting.settings.theme),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
