import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import Footer from "./Footer";
import useCustomStyles from "../../customehooks/useCustomeStyles";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const styles = () => ({
  homeRoot: {
    // background: 'url("/images/Know_ Your_ Coach_bg.png")',
    backgroundSize: "cover !important",
    backgroundRepeat:'no-repeat !important'
  },
});

const scheduleAndTimingStyles = {
  background: 'url("/images/asana_info_bg.png")',
  backgroundSize: "cover",
};

const asanInfostyles = {
  background: 'url("/images/asana_info_bg.png")',
  backgroundSize: "cover",
};

const otherStyles = {
  background: 'url("/images/Know_ Your_ Coach_bg.png")',
  backgroundSize: "cover",
};

export default function HomeLayout({ children }) {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const [backgroundStyle, setBackgroundStyle] = useState({});

  useEffect(() => {
    const pathName = location.pathname;
    const newBackgroundStyle =
      pathName === "/asana-info"
        ? asanInfostyles
        : pathName === "/schedule-and-timing"
        ? scheduleAndTimingStyles
        : otherStyles;

    setBackgroundStyle(newBackgroundStyle);
  }, [location.pathname]);

  return (
    <div
      className={`${classes?.homeRoot} h-full min-h-screen flex flex-col justify-between`}
      style={{ ...backgroundStyle, backgroundSize: "cover" }}
    >
      <Topbar />

      <div>{children}</div>
      <Footer />
    </div>
  );
}
