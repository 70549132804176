import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import useCustomStyles from '../../customehooks/useCustomeStyles'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'
import Terms from '../TermsAndConditions/Terms'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation } from 'react-router-dom'
import { sendEmail } from '../../utils/api'

const styles = () => ({
  contactRoot: {
    padding: '50px 0',
    margin: '150px 0',
    background: '#E0E5B6',
    '& h2': {
      fontFamily: 'Amethysta',
      fontWeight: 400,
      lineHeight: '90px',
    },
    '& p': {
      color: '#023927',
      fontWeight: 400,
    },
    '& label': {
      fontSize: '16px',
      color: '#344054',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '& .phone-input': {
      width: '100%',
      '& input': {
        width: '100%',
        height: '50px',
        borderRadius: '8px',
      },
    },
    messagetextfield: {
      '& .MuiOutlinedInput-root': {
        height: '115px',
        '& textarea': {
          height: '100% !important',
          padding: '0',
        },
      },
    },
    '& button': {
      borderRadius: '8px',
    },
  },
})

const data = [
  {
    Classtitle: 'Personalized One-to-One Yoga Class',
    image: '/images/PersonalizedYoga.png',
    description: `<p>Experience the transformative power of personalized yoga instruction tailored to your individual needs. In this one-to-one session, you'll receive focused attention from an experienced instructor, who will guide you through poses, breathing techniques, and meditation practices designed specifically for your body, goals, and experience level. Whether you're looking to deepen your practice, address specific health concerns, or simply enjoy a customized yoga experience, this session offers the perfect environment to explore and grow at your own pace.
      <br/>
      <br/>
      Reconnect with your inner self, improve flexibility, and build strength with a class uniquely designed just for you.
      <br />
      </p>`,
    benefitsTitle: 'Benefits of Personalized Yoga',
    benefits: [
      "Tailored to Your Needs: Each session is customized to focus on your specific goals, whether it's improving flexibility, strength, balance, or addressing particular health concerns such as back pain or stress.",
      "Individual Attention: With the instructor's undivided attention, you receive immediate feedback and adjustments, ensuring proper alignment and technique, which can help prevent injury and enhance the effectiveness of each pose.",
      'Flexible Scheduling: One-on-one classes offer the convenience of scheduling sessions at times that work best for you, allowing for a consistent practice that fits into your lifestyle.',
      "Progress at Your Own Pace: You can progress at a pace that suits your body and experience level, making the practice accessible whether you're a beginner or an advanced yogi.",
      'Enhanced Mind-Body Connection: Personalized instruction helps you to better connect with your body and breath, deepening your practice and promoting mindfulness.',
      "Focus on Personal Goals: Whether you're aiming to reduce stress, improve physical fitness, or achieve specific yoga milestones, the session is designed to help you meet your personal wellness goals.",
      'Privacy and Comfort: Practicing yoga in a private setting allows you to focus without the distractions of a group class, creating a comfortable environment to explore and refine your practice.',
      'Holistic Health Benefits: Beyond physical fitness, one-to-one yoga classes can support mental clarity, emotional well-being, and overall life balance, tailored to your individual lifestyle and needs.',
    ],
  },
  {
    Classtitle: 'Pre-Natal Yoga Class',
    image: '/images/PreNatlYoga.png',
    description: `<p>Nurture your body and mind during pregnancy with our specialized Pre-Natal Yoga class. Designed specifically for expectant mothers, this gentle and supportive practice focuses on the unique physical and emotional needs of pregnancy. Through carefully chosen poses, breathing techniques, and relaxation exercises, you'll enhance your strength, flexibility, and balance, all while creating a deeper connection with your growing baby.
      <br/>
      <br/>
      Our experienced instructors will guide you through movements that help alleviate common pregnancy discomforts, such as back pain and swelling, while also preparing your body for labor and delivery. Whether you're a seasoned yogi or new to yoga, this class provides a safe and calming space to embrace the journey of motherhood, promoting overall well-being for both you and your baby.
      <br />
      <br />
      Empower your pregnancy with the benefits of yoga, fostering a sense of peace, confidence, and joy during this special time.
      </p>`,
    benefitsTitle: 'Benefits of Pre-Natal Yoga',
    benefits: [
      'Customized Practice: Each session is tailored to your specific pregnancy stage, body type, and any unique needs or concerns, ensuring a safe and effective practice that aligns with your individual journey.',
      'Focused Attention: Receive personalized guidance and adjustments from a knowledgeable instructor, helping you to practice with proper alignment and technique, which can reduce the risk of injury and enhance the overall experience.',
      'Alleviation of Discomfort: Targeted yoga poses and stretches can help relieve common pregnancy discomforts such as lower back pain, sciatica, swelling, and fatigue, promoting a more comfortable pregnancy.',
      'Stress Reduction: Learn breathing techniques and mindfulness practices that can help manage stress and anxiety, creating a sense of calm and relaxation that benefits both you and your baby.',
      'Preparation for Labor: Strengthen the muscles used in childbirth and practice breathing and relaxation techniques that can be useful during labor, making you feel more confident and prepared for delivery.',
      'Improved Sleep: Gentle yoga can help improve sleep quality by promoting relaxation and reducing physical discomfort, which is especially beneficial during pregnancy when sleep can be challenging.',
      "Enhanced Bonding: The personalized setting allows for a deeper connection with your baby, as you take time to focus on your body's changes and your growing baby, fostering a sense of bonding and awareness.",
      'Flexibility in Scheduling: One-to-one sessions offer the convenience of choosing times that work best with your schedule, allowing you to maintain a consistent practice throughout your pregnancy.',
      'Support for Emotional Well-being: The individualized approach provides emotional support, helping you navigate the emotional ups and downs of pregnancy with greater ease and resilience.',
      'Postnatal Preparation: Personalized sessions can also include elements that prepare you for the postpartum period, such as exercises that promote recovery and strengthen your body after childbirth.',
    ],
  },
  {
    Classtitle: 'Yoga For Kids',
    image: '/images/YogaForKids.png',
    description: `<p>Introduce your child to the joys of movement and mindfulness with our fun and engaging Yoga for Kids class. Designed specifically for young bodies and minds, this class blends playful poses, imaginative activities, and calming relaxation techniques to create an enriching experience for children of all ages. Through a mix of storytelling, games, and age-appropriate yoga postures, kids will explore balance, flexibility, and coordination in a supportive and non-competitive environment.
      <br/>
      <br/>
      Our instructors make yoga exciting and accessible, encouraging creativity and self-expression while fostering a sense of calm and focus. Each session is crafted to be both educational and entertaining, helping children to build physical strength, emotional resilience, and a positive body image.
      <br />
      </p>`,
    benefitsTitle: 'Benefits of Yoga for Kids',
    benefits: [
      'Physical Development: Yoga helps improve flexibility, strength, coordination, and balance, supporting overall physical development in a fun and engaging way.',
      'Improved Focus and Concentration: Through mindful breathing and movement, yoga teaches kids how to focus their attention, which can enhance concentration in school and other activities.',
      'Stress Relief: Even kids experience stress, and yoga provides them with tools to relax, manage anxiety, and build emotional resilience, helping them navigate their feelings more effectively.',
      'Enhanced Creativity and Imagination: The playful nature of yoga for kids, often incorporating stories and games, stimulates creativity and imagination, making the practice both enjoyable and beneficial.',
      'Social Skills and Teamwork: Group activities in yoga classes encourage collaboration, communication, and respect for others, fostering important social skills in a supportive environment.',
      'Improved Body Awareness and Self-Esteem: Yoga teaches kids to listen to their bodies and appreciate what they can do, promoting a positive body image and boosting self-esteem.',
      'Better Sleep: Regular yoga practice can help improve sleep patterns, as the relaxation techniques and physical activity promote restful sleep.',
      'Foundation for a Healthy Lifestyle: By introducing yoga at a young age, children develop a foundation for a lifelong appreciation of movement, mindfulness, and well-being.',
      'Emotional Regulation: Yoga helps children recognize and manage their emotions, giving them healthy ways to express and cope with their feelings.',
      'Fun and Enjoyment: Most importantly, yoga for kids is fun! It’s a joyful way to move, play, and explore, making exercise something to look forward to.',
    ],
  },
  {
    Classtitle: 'Yoga For All',
    image: '/images/full-shot-women-standing-one-leg.png',
    description: `<p>Discover the transformative power of yoga with our inclusive "Yoga for All" class, designed to accommodate practitioners of all ages, abilities, and experience levels. Whether you're a seasoned yogi or brand new to the practice, this class offers a welcoming and supportive environment where everyone can explore yoga at their own pace. Our skilled instructors guide you through a balanced sequence of poses, breathing techniques, and meditation practices that can be easily adapted to meet your individual needs.
      <br/>
      <br/>
      In this class, the emphasis is on accessibility and community, ensuring that everyone, regardless of physical ability or experience, can experience the mental and physical benefits of yoga. Modifications and variations are offered for every pose, making it possible for each participant to find their own expression of the practice.
      <br />
      <br />
      Come as you are, and leave feeling rejuvenated, centered, and connected.
      </p>`,
    benefitsTitle: 'Benefits of Yoga for All',
    benefits: [
      'Inclusivity and Accessibility: The class is designed to be accessible to everyone, regardless of age, fitness level, or physical ability, promoting a sense of belonging and community.',
      'Physical Well-Being: Yoga helps improve flexibility, strength, balance, and overall physical fitness, contributing to a healthier body for people at any stage of life.',
      'Stress Relief and Relaxation: The combination of mindful movement, breath work, and meditation helps reduce stress, calm the mind, and promote relaxation, benefiting mental health.',
      'Adaptability: With modifications and variations available for every pose, participants can tailor their practice to meet their individual needs and abilities, ensuring a safe and effective experience.',
      'Enhanced Focus and Mindfulness: Yoga encourages mindfulness and presence, helping to improve concentration, mental clarity, and overall cognitive function.',
      'Emotional Balance: The practice fosters emotional resilience and balance, offering tools to manage emotions and cultivate a sense of inner peace and well-being.',
      'Community Connection: Practicing in a diverse group fosters a sense of connection and support, enhancing social well-being and creating a shared experience of growth and healing.',
      'Holistic Health: Yoga for All promotes a holistic approach to health, integrating physical, mental, and emotional well-being in a way that supports overall life balance.',
      'Boosted Energy Levels: Regular yoga practice revitalizes the body and mind, leading to increased energy levels and a more positive outlook on life.',
      'Lifelong Practice: By accommodating different levels of experience, Yoga for All encourages a lifelong appreciation of yoga as a practice for health and well-being.',
    ],
  },
]

export default function ContactUs(props) {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [openTermsDialog, setOpenTermsDialog] = useState(false)
  const [phone, setPhone] = useState('')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const title = queryParams.get('Classtitle')
  const aasanaData = data.find((asana) => asana.Classtitle === title)
  const [service, setService] = useState(aasanaData?.Classtitle || '')

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    serviceType: aasanaData?.Classtitle || '',
    message: '',
  })

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!termsAccepted) {
      setError('You must accept the Terms and Conditions.')
      return
    }

    setLoading(true)
    setError(null)
    setSuccessMessage(null)

    try {
      const response = await sendEmail(formData)
      setSuccessMessage(response.message)
      // Reset form data
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        serviceType: '',
        message: '',
      })
      setTermsAccepted(false)
    } catch (err) {
      if (err.errors) {
        setError(err.errors.join(' '))
      } else if (err.error) {
        setError(err.error)
      } else {
        setError('An unexpected error occurred.')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true)
  }

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false)
  }

  return (
    <div className={props?.dialogue ? '' : classes?.contactRoot}>
      {props?.dialogue && (
        <Box display='flex' justifyContent='flex-end'>
          <IconButton onClick={props?.onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Container maxWidth='lg'>
        <Typography variant='h5' color='secondary' className='text-center'>
          Contact us
        </Typography>

        <Typography
          variant='h3'
          color='primary.dark'
          className='text-center text-4xl md:text-5xl'
        >
          Get in touch
        </Typography>

        <Typography
          variant='h5'
          color='primary.light'
          className='text-center mt-6'
        >
          We’d love to hear from you. Please fill out this form.
        </Typography>

        <div className='w-full max-w-[900px] mx-auto mt-6'>
          <form
            onSubmit={handleSubmit}
            className='w-full max-w-[900px] mx-auto mt-6'
          >
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <Box>
                <label>First Name</label>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder='First Name'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </Box>

              <Box>
                <label>Last Name</label>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder='Last Name'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </Box>
            </div>

            <Box mt={2}>
              <label>Email</label>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='you@company.com'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Box>

            <Box mt={2}>
              <label>Phone number</label>
              <PhoneInput
                country={'in'}
                value={formData.phoneNumber}
                onChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                placeholder='Enter phone number'
                inputProps={{
                  name: 'phoneNumber',
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  width: '100%',
                  height: '50px',
                  borderRadius: '8px',
                }}
                className='phone-input'
              />
            </Box>

            <Box mt={2}>
              <label>Select Service</label>
              <Select
                fullWidth
                variant='outlined'
                name='serviceType'
                value={formData.serviceType}
                onChange={handleInputChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  height: '50px',
                  '&:focus': {
                    boxShadow: '0 0 0 0.2rem rgba(63, 81, 181, 0.25)',
                  },
                }}
              >
                <MenuItem value='' disabled>
                  <span
                    style={{
                      fontSize: '12px !important',
                      fontWeight: '400 !important',
                      color: '#667085',
                    }}
                  >
                    Select a Service
                  </span>
                </MenuItem>
                <MenuItem value='Yoga For All'>Yoga For All</MenuItem>
                <MenuItem value='Yoga For Kids'>Yoga For Kids</MenuItem>
                <MenuItem value='Pre-Natal Yoga Class'>
                  Pre-Natal Yoga Class
                </MenuItem>
                <MenuItem value='Personalized One-to-One Yoga Class'>
                  Personalized One-to-One Yoga Class
                </MenuItem>
              </Select>
            </Box>

            <div className='h-[150px] mt-4'>
              <label>Message</label>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='Your Message'
                name='message'
                value={formData.message}
                onChange={handleInputChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '115px',
                    '& textarea': {
                      height: '100% !important',
                      padding: '0',
                    },
                  },
                }}
                multiline
                rows={5}
                required
              />
            </div>

            <div className='mt-2'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                }
                label={
                  <Typography variant='body2' color='secondary.gray'>
                    You agree to our friendly{' '}
                    <span
                      onClick={handleOpenTermsDialog}
                      className='underline cursor-pointer'
                    >
                      Terms and Conditions.
                    </span>
                  </Typography>
                }
              />
            </div>

            {error && (
              <Typography color='error' className='mt-2'>
                {error}
              </Typography>
            )}

            {successMessage && (
              <Typography color='primary' className='mt-2'>
                {successMessage}
              </Typography>
            )}

            <div className='mt-4'>
              <Button
                variant='contained'
                fullWidth
                type='submit'
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </Button>
            </div>
          </form>
        </div>
        <Terms open={openTermsDialog} onClose={handleCloseTermsDialog} />
      </Container>
    </div>
  )
}
