import React from "react";
import { Helmet } from "react-helmet";
export default function Page({title,children}) {

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>{children}</div>
    </>
  );
}
