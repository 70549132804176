// src/utils/api.js

import axios from 'axios'

const API_BASE_URL = 'https://apnatrack.com/email-service'
// const API_BASE_URL = 'http://localhost:5050'

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000, // 10 seconds timeout
  headers: {
    'Content-Type': 'application/json',
  },
})

export const sendEmail = async (formData) => {
  try {
    console.log(formData)
    const response = await axiosInstance.post('/send-email', formData)
    return response.data
  } catch (error) {
    // Handle error response
    if (error.response && error.response.data) {
      // Server responded with a status other than 2xx
      return Promise.reject(error.response.data)
    } else {
      // Network error or request was not made
      return Promise.reject({ error: 'Network error. Please try again later.' })
    }
  }
}

export const subscribeEmail = async (email) => {
  try {
    const response = await axiosInstance.post('/subscribe', { email })
    return response.data
  } catch (error) {
    // Handle error response
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    } else {
      return Promise.reject({ error: 'Network error. Please try again later.' })
    }
  }
}
