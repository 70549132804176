import { AppBar, Button, IconButton, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useCustomStyles from "../../customehooks/useCustomeStyles";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { RiMenuLine } from "react-icons/ri";

//navigation
const navigationLinks = [
  {
    title: "Home",
    href: "/",
  },

  {
    title: "Know The Coach",
    href: "/know-your-coach",
  },

  {
    title: "Types Of Yoga",
    href: "/types-of-yoga",
  },

  // {
  //   title: "About Us",
  //   href: "#",
  // },

  {
    title: "Schedule & Timing",
    href: "/schedule-and-timing",
  },
];

//styles
const styles = () => ({
  appBarRoot: {
    position: "static",
    background: "transparent",

    "& .contact-btn": {
      padding: "15px 54px",
      borderRadius: "30px",
      width: "100%",
      maxWidth: "192px",
      whiteSpace: "nowrap",
      letterSpacing: "0.65px",
    },
  },
});

export default function Topbar() {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [mobileView, setMobileView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      setMobileView(window.innerWidth < 1220);
    };

    setResponsiveness();
    window.addEventListener("resize", setResponsiveness);

    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  return (
    <AppBar elevation={0} className={classes?.appBarRoot}>
      <div>
        {mobileView ? (
          <DisplayMobile
            anchorEl={anchorEl}
            handleMenuOpen={handleMenuOpen}
            handleMenuClose={handleMenuClose}
          />
        ) : (
          <DisplayDesktop />
        )}
      </div>
    </AppBar>
  );
}

function TopbarContent({ onLinkClick, view }) {
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div className="flex items-center justify-between p-4">
      {view === "desktop" && (
        <div className="flex-shrink-0">
          <img src="/images/logo.svg" alt="logo" />
        </div>
      )}

      <div className="flex-1">
        {/* Change flex direction for mobile view */}
        <div className={`flex ${view === "mobile" ? "justify-start" : "justify-center"}`}>
          {view === "desktop" ? (
            <ul className="flex items-center gap-20 mb-5">
              {navigationLinks.map((link, i) => (
                <Typography
                  color="secondary"
                  key={i}
                  component={Link}
                  to={link.href}
                  onClick={onLinkClick}
                  className="navLink"
                  style={{
                    textDecoration: link.href === pathName ? "underline" : "none",
                    transition: "text-decoration 0.3s ease",
                    fontSize: "17px",
                    fontWeight: 600,
                    lineHeight: 1.3,
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.textDecoration = "underline";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.textDecoration = link.href === pathName ? "underline" : "none";
                  }}
                >
                  <li>{link.title}</li>
                </Typography>
              ))}
            </ul>
          ) : (
            <ul className="flex flex-col justify-start items-start gap-16">
              {navigationLinks.map((link, i) => (
                <Typography
                  variant="body2"
                  color="secondary"
                  key={i}
                  component={Link}
                  to={link.href}
                  onClick={onLinkClick}
                  className="navLink"
                  style={{
                    textDecoration:
                      link.href === pathName ? "underline" : "none",
                  }}
                >
                  <li>{link.title}</li>
                </Typography>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}


const DisplayMobile = ({ anchorEl, handleMenuClose, handleMenuOpen }) => (
  <div className="flex justify-between items-center p-6 w-full" >
    <img src="/images/Logo.png" alt="logo" style={{ height: "50px", width: "50px" }} />
    <IconButton
      edge="end"
      color="inherit"
      onClick={handleMenuOpen}

    >
      <RiMenuLine />
    </IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="p-4">
        <TopbarContent view="mobile" onLinkClick={handleMenuClose} />
      </div>
    </Popover>
  </div>
);

function DisplayDesktop() {
  return <div>{<TopbarContent view="desktop" />}</div>;
}
