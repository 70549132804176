import React, { Suspense, Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import NotFound from "./pages/NotFound/Index";
import PageLoading from "./components/PageLoading";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<PageLoading />}>
        <RenderRoutes data={routes} />
      </Suspense>
    </Router>
  );
}

function RenderRoutes({ data }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        {data.map((route, i) => {
          const Component = route.component;
          const Layout = route.layout || Fragment;
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Layout>
                  {route.routes ? (
                    <RenderRoutes data={route.routes} />
                  ) : (
                    <Component />
                  )}
                </Layout>
              }
            />
          );
        })}
        <Route path="*" element={<NotFound />} />{" "}
      </Routes>
    </LocalizationProvider>
  );
}
